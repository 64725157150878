import { Grid } from "@mui/material";


const Header = () => {
    return (
        <Grid className="header">
            <img className="header__logo" src="images/general/BMJ_Logo_Videoportal_rgb.svg" alt="logo"/>
        </Grid>
    );
}


export default Header;