import { Divider, Grid, Stack, Typography } from "@mui/material";
import AboutPanel from "../components/AboutPanel";
import { useMediaAllowAccess } from "../utils/hooks";
import ButtonsGroup from "../components/ButtonsGroup";
import Header from "../components/Header";
import ErrorModal from "../components/error-modal/ErrorModal";

const SettingsView = () => {
    const { mediaAllowed, mediaError } = useMediaAllowAccess();

    const text = {
        back : `zurück zum Digitalen Gerichtssaal`,
        next : `Test starten`
    };

    const navigation = {
        next : `/video`,
    }
    
    return (
        <Grid className="settings-page">
            <Header />
            {!mediaAllowed && <ErrorModal error={mediaError}/>}
            <Grid className="main-settings-page">
                <Grid className="title">
                    <Typography>Wie gut ist Ihre Verbindung?</Typography>
                </Grid>
                <Grid className="text">
                    <Typography className="text-bold">Ob Kamera und Mikrofon aktiv sind und Ihre Verbindungsqualität ausreicht, überprüfen Sie hier selbst. In drei Schritten testen Sie</Typography>
                    <Typography className="text-thin">In drei Schritten testen Sie</Typography>
                    <Divider sx={{marginBottom : `35px`}}>
                        <Stack className="test-icon" spacing={1} direction='row'>
                                <img alt="video" src="images/landing/videocam_landing.svg"></img>
                                <Typography>Video</Typography>
                                <Divider></Divider>
                                <img alt="microphone" src="images/landing/mic_landing.svg"></img>
                                <Typography>Camera</Typography>
                                <Divider></Divider>
                                <img alt="speaker" src="images/landing/volume_landing.svg"></img>
                                <Typography>Speaker</Typography>
                        </Stack>
                    </Divider>
                    <Typography className="text-bold">Um den Digitalen Gerichtssaal zu nutzen, müssen Sie der Nutzung von Kamera und Mikrofon zustimmen. Dazu klicken Sie je nach Browser auf „zulassen“ oder „erlauben“. </Typography>
                </Grid>
                <Grid className="img-set">
                    <figure>
                        <img className="img-border" src="images/landing/google-chrome.svg" alt="google-chrome"/>
                        <img className="arrow1" src="images/landing/arrow.svg" alt="arrow"/>
                        <figcaption> Google Chrome </figcaption>
                    </figure>
                    <figure>
                        <img src="images/landing/safari.svg" alt="safari"/>
                        <img className="arrow2" src="images/landing/arrow.svg" alt="arrow"/>
                        <figcaption> Apple Safari </figcaption>
                    </figure>
                    <figure>
                        <img className="img-border" src="images/landing/mozilla-firefox.svg" alt="mozilla-firefox"/>
                        <img className="arrow3" src="images/landing/arrow.svg" alt="arrow"/>
                        <figcaption> Mozilla FireFox </figcaption>
                    </figure>
                </Grid>
                <Grid>
                    <Typography className="text-thin">Die beim Test entstehenden Aufnahmen sind nur an Ihrem Rechner zu sehen und werden nicht gespeichert.</Typography>
                </Grid>
                <Divider className="divider" variant="middle"/>
                <ButtonsGroup text={text} navigation={navigation} />
                <Grid className="footer"></Grid>
                <AboutPanel/>
            </Grid>
        </Grid>
    )
}


export default SettingsView;