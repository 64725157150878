import { Button } from "@mui/material";
import { useState } from "react";


const AboutPanel = () => {

    const [opened, setOpened] = useState(false);


    return (
        <div className={'about-panel ' + (opened ? 'about-panel--opened' : 'about-panel--closed') } >
            <Button  className={'impressumbutton'} onClick={() => setOpened(!opened)}>
                <img className={'upArrow'} alt={'up'} src={'images/general/upArrow.svg'}
                     style={{
                         transform: opened ? 'rotate(-180deg)' : ''
                     }}/>
                <div className={'impressum'}>IMPRESSUM</div>
            </Button>
            <div className={'impressumContentContainer'} >
                <div className={'col1'}>
                    <h1>Impressum</h1>
                    <div>

                        <h2>Anbieter </h2>
                        Bundesministerium der Justiz (BMJ) <br/>
                        Mohrenstraße 37<br/>
                        10117 Berlin<br/>
                        Telefon: 030/ 18 580 0<br/>
                        Fax: 030/ 18 580 - 95 25<br/>
                        E-Mail: email@bmj.bund.de<br/>
                        www.bmj.de

                    </div>

                    <div>
                        <h2>Gesetzlicher Vertreter</h2>
                        Das Ministerium wird durch den Bundesminister der Justiz vertreten.

                    </div>

                    <div>
                        <h2>Verantwortlich</h2>
                        Vera Verantwortlich
                    </div>

                    <div>
                        <h2>Design und Technische Umsetzung</h2>
                        Nordeck IT + Consulting, Hamburg
                    </div>

                    <div>
                        <h2>Hosting und Betrieb</h2>
                        Hostinganbieter
                    </div>


                    <div>
                        <h2>Bildnachweis</h2>
                        Das Logo ist Eigentum der oben genannten Behörde. Das Startbild ist ein Stockphoto von iStockphoto.com.

                    </div>


                </div>

                <div className={'col2'}>
                    <h1>Rechtliche Hinweise (DSGVO ?)</h1>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </div>

            </div>
        </div>
    );
}

export default AboutPanel;