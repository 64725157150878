import { SelectorButtonsType } from "../utils/types";

const SelectorButtons = ({ mode } : SelectorButtonsType) => {
    return (
        <div className={'selector__buttons hide-buttons'}>
            <ul>
                <li className={mode === `video` ? `video-active` : `video`}>
                    <span style={mode !== `video` ? { color : `#888888`} : {}}>Video</span>
                </li>
                <li className={mode === `microphone` ? `mic-active` : `mic`}>
                    <span style={mode !== `microphone` ? { color : `#888888`} : {}}>Mikrofon</span>
                </li>
                <li className={mode === `speaker` ? `speaker-active` : `speaker`}>
                    <span style={mode !== `speaker` ? { color : `#888888`} : {}}>Lautsprecher</span>
                </li>
            </ul>
        </div>
    );
}


export default SelectorButtons;