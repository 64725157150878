import { useReducer } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import GlobalState from './context';
import { ReactQueryDevtools } from "react-query/devtools";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Routes from "./routes/routes";
import theme from "./themes/theme";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 2,
            staleTime: 0
        },
    },
});

function App() {
  const [state, updateState] = useReducer((state: any, next: any) => {
    return {...state, ...next};
  }, {});


  return (
      <GlobalState.Provider value={{state, updateState}}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes/>
          </ThemeProvider>
        </QueryClientProvider>
      </GlobalState.Provider>
  );
}

export default App;
